<script>
export default {
  name: "documentIcon",
  props: {
    color: String
  },
}
</script>

<template>

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M12 5.30165V21.3317C11.83 21.3317 11.65 21.3017 11.51 21.2217L11.47 21.2017C9.55 20.1517 6.2 19.0517 4.03 18.7617L3.74 18.7217C2.78 18.6017 2 17.7017 2 16.7417V4.66165C2 3.47165 2.97 2.57165 4.16 2.67165C6.26 2.84165 9.44 3.90165 11.22 5.01165L11.47 5.16165C11.62 5.25165 11.81 5.30165 12 5.30165Z" :fill="color"/>
        <path d="M22 4.66954V16.7395C22 17.6995 21.22 18.5995 20.26 18.7195L19.93 18.7595C17.75 19.0495 14.39 20.1595 12.47 21.2195C12.34 21.2995 12.18 21.3295 12 21.3295V5.29954C12.19 5.29954 12.38 5.24954 12.53 5.15954L12.7 5.04954C14.48 3.92954 17.67 2.85954 19.77 2.67954H19.83C21.02 2.57954 22 3.46954 22 4.66954Z" :fill="color"/>
        <path d="M7.75 9.23828H5.5C5.09 9.23828 4.75 8.89828 4.75 8.48828C4.75 8.07828 5.09 7.73828 5.5 7.73828H7.75C8.16 7.73828 8.5 8.07828 8.5 8.48828C8.5 8.89828 8.16 9.23828 7.75 9.23828Z" :fill="color"/>
        <path d="M8.5 12.2383H5.5C5.09 12.2383 4.75 11.8983 4.75 11.4883C4.75 11.0783 5.09 10.7383 5.5 10.7383H8.5C8.91 10.7383 9.25 11.0783 9.25 11.4883C9.25 11.8983 8.91 12.2383 8.5 12.2383Z" :fill="color"/>
    </svg>
</template>

<style scoped>

</style>