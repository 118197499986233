<template>
    <div class="exams">
        <h5 class="title">{{ $t("exams") }} ({{ exams?.length }})</h5>
        <ul class="exams_card">
            <li class="exam_card" v-for="(exam, index) in exams" :key="index">
                <!-- :to="`${routeName == 'coursesDetailsPage' ? '/exam/'+exam.id : (routeName == 'lessonExam' || routeName == 'lesson') ? '/lesson/'+lessonID+'/exam/'+exam.id : ''}`" -->
                <div class="exam_check" v-if="exam.available">
                    <p class="name">
                        <ExamIcon :color="PrimaryColor" />
                        {{ exam.name }}
                    </p>
                  <div class="start">
                    <button type="button" class="btn PrimaryButton px-4" @click="examOpen(exam)">{{$t("start")}}</button>
                  </div>
                </div>
                <div class="exam_check disabled" v-tooltip.top="'هذا الامتحان غير متاح لك'" v-else>
                    <p class="name">
                        <ExamIcon :color="PrimaryColor" />
                        {{ exam.name }}
                    </p>
                    <div class="lock">
                        <LockIcon :color="PrimaryColor" />
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <Dialog class="dialog_lesson exam_lesson" v-model:visible="visible" :closeOnEscape="false" :closeIcon="{ enabled: false }" modal :style="{ width: '100%', height: '100vh' }">
        <Exam :examDialog="exam" />
    </Dialog>
</template>

<script>
import LockIcon from "@/assets/icons/lockIcon.vue";
import ExamIcon from  "@/assets/icons/examIcon.vue";
import Exam from "@/components/include/exams/examComponent.vue";
import Dialog from 'primevue/dialog';

export default {
    name: "exams-card",
    data() {
        return {
            PrimaryColor: JSON.parse(localStorage.getItem("web_status"))?.primary_color,
            routeName: this.$route.name,
            lessonID: this.$route.params.id,
            visible: false,
            exam: {}
        }
    },
    props: {
        exams: Array
    },
    components:{
        LockIcon,
        ExamIcon,
        Exam,
        Dialog
    },
    methods: {
        examOpen(exam) {
            this.exam = exam;
            this.visible = true
        }
    }
}
</script>