<template>
    <div class="col-lg-9 col-md-12 col-12">
        <div class="lesson">
            <h3 class="title">{{ session.name }}</h3>
            <p class="description">{{ session.description }}</p>
            <!-- {{ videoEmbedLink + '?modestbranding=1&rel=0' }} -->
            <!-- <iframe v-if="session.name" width="100%" height="600" :src="videoEmbedLink" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
            <div v-if="loader" class="loading-indicator"><img :src="logo" class="loader_video" alt="logo"></div>
            <Youtube :videoId="videoId" v-else-if="session.name && videoId" />
        </div>
    </div>
</template>

<script>
import Youtube from '@/components/include/video/youtube.vue';

export default {
    name: "lesson-component",
    props: {
        session: Object
    },
    data() {
        return {
            loader: true,  // Initialize loader state
            decodedLink: "",
            videoEmbedLink: "",
            videoId: "",
            logo: JSON.parse(localStorage.getItem("web_status"))?.logo,
        }
    },
    components: {
        Youtube
    },
    watch: {
        session: {
            handler() {
                // this.loader = true;
                this.decodeVideoLink();
                // this.loader = false;
            },
            immediate: true,
            deep: true
        },
        videoId() {
            this.loadVideo();
        }
    },
    methods: {
        loadVideo() {
            this.loader = true;
            setTimeout(() => {
                this.loader = false;
            }, 5);
        },
        decodeVideoLink() {
          const initLink = (this.session?.video?.link ?? "")
              .replaceAll('%3A', ":")
              .replaceAll("%2F", "/")
              .replaceAll("%3F", "?")
              .replaceAll("%3D", "=")
              .replaceAll("%26", "&");

          const firstLink = initLink.substring(this.session?.video?.random_number);

          const secondLink = firstLink.substring(2);

          const multiNumber = parseInt(firstLink.substring(0, 2));
          const hiddenNumber = Math.floor(multiNumber / this.session?.video?.random_number);

          const encodedLink = secondLink.substring(0, secondLink.length - hiddenNumber);

          // Implement your own utf8 and base64Url decoding logic here
          this.decodedLink = this.decodeBase64Url(encodedLink);


          let videoId;

          // Check if the link is a shortened youtu.be link
          if (this.decodedLink.includes("youtu.be/")) {
            const regexShort = /youtu\.be\/([^?]+)/;
            const shortMatch = this.decodedLink.match(regexShort);
            if (shortMatch) {
              videoId = shortMatch[1];
            }
          } else {
            // Regular expression to match the video ID from full YouTube links (v=)
            const regex = /[?&]v=([^&]+)/;
            const videoIdMatch = this.decodedLink.match(regex);
            if (videoIdMatch) {
              videoId = videoIdMatch[1];
            }
          }

          if (videoId) {
            // Construct the embedded YouTube video link
            this.videoEmbedLink = `https://www.youtube.com/embed/${videoId}`;
            this.videoId = videoId;
            this.showVideoEmbed = true;
          } else {
            console.log('Invalid YouTube video link');
          }
        },
        decodeBase64Url(encodedLink) {
            // Implement your own utf8 and base64Url decoding logic here
            // Example using js-base64 library (you may need to install it)
            // import { Base64 } from 'js-base64';
            // const decoded = Base64.decode(encodedLink);

            // For example purposes, using atob and decodeURIComponent
            const decoded = decodeURIComponent(escape(atob(encodedLink))) ?? "";

            return decoded;
        },
    },
    // mounted() {
    //     this.decodeVideoLink();
    // },
}
</script>