<template>
    <div class="mt-3">
        <h5 class="title">{{ $t("videos") }} ({{ sessions?.length }})</h5>
        <ul class="lessons_card">
            <li class="lesson_card session_card" v-for="(session, index) in sessions" :key="index">
                <div class="click" v-if="session.available">
                    <p class="name">
                        <VideoIcon :color="PrimaryColor" />
                        {{ session.name }}
                    </p>
                    <button type="button" class="btn PrimaryButton px-4" @click="showSession(session)">{{$t("show")}}</button>
                </div>
                <div class="click disabled" v-tooltip.top="$t('not_available_lesson_for_you')" v-else>
                    <p class="name">
                        <VideoIcon :color="PrimaryColor" />
                        {{ session.name }}
                    </p>
                    <div class="lock">
                      <LockIcon :color="PrimaryColor" />
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <Dialog class="dialog_lesson" v-model:visible="visible" modal :header="session.name" :style="{ width: '100%', height: '100vh' }">
        <div class="col-12">
            <div class="lesson">
                <p class="description">{{ session.description }}</p>
                <div v-if="loader" class="loading-indicator"><img :src="logo" class="loader_video" alt="logo"></div>
                <Youtube :videoId="videoId" v-else-if="session.name && videoId" />
            </div>
        </div>
    </Dialog>
</template>

<script>
import LockIcon from "@/assets/icons/lockIcon.vue";
import VideoIcon from "@/assets/icons/videoIcon.vue";
import Youtube from '@/components/include/video/youtube.vue';
import Dialog from 'primevue/dialog';

export default {
    name: "sessions-card",
    data() {
        return {
            PrimaryColor: JSON.parse(localStorage.getItem("web_status"))?.primary_color,
            session: {},
            visible: false,
            loader: true,  // Initialize loader state
            decodedLink: "",
            videoEmbedLink: "",
            videoId: "",
            logo: JSON.parse(localStorage.getItem("web_status"))?.logo,
        }
    },
    components: {VideoIcon, LockIcon, Dialog, Youtube},
    props: {
        sessions: Array
    },
    watch: {
        session: {
            handler() {
                this.decodeVideoLink();
            },
            immediate: true,
            deep: true
        },
        videoId() {
            this.loadVideo();
        }
    },
    methods: {
        showSession(session) {
            this.session = session;
            this.visible = true;
        },
        loadVideo() {
            this.loader = true;
            setTimeout(() => {
                this.loader = false;
            }, 5);
        },
        decodeVideoLink() {
          const initLink = (this.session?.video?.link ?? "")
              .replaceAll('%3A', ":")
              .replaceAll("%2F", "/")
              .replaceAll("%3F", "?")
              .replaceAll("%3D", "=")
              .replaceAll("%26", "&");

          const firstLink = initLink.substring(this.session?.video?.random_number);

          const secondLink = firstLink.substring(2);

          const multiNumber = parseInt(firstLink.substring(0, 2));
          const hiddenNumber = Math.floor(multiNumber / this.session?.video?.random_number);

          const encodedLink = secondLink.substring(0, secondLink.length - hiddenNumber);

          // Implement your own utf8 and base64Url decoding logic here
          this.decodedLink = this.decodeBase64Url(encodedLink);


          let videoId;

          // Check if the link is a shortened youtu.be link
          if (this.decodedLink.includes("youtu.be/")) {
            const regexShort = /youtu\.be\/([^?]+)/;
            const shortMatch = this.decodedLink.match(regexShort);
            if (shortMatch) {
              videoId = shortMatch[1];
            }
          } else {
            // Regular expression to match the video ID from full YouTube links (v=)
            const regex = /[?&]v=([^&]+)/;
            const videoIdMatch = this.decodedLink.match(regex);
            if (videoIdMatch) {
              videoId = videoIdMatch[1];
            }
          }

          if (videoId) {
            // Construct the embedded YouTube video link
            this.videoEmbedLink = `https://www.youtube.com/embed/${videoId}`;
            this.videoId = videoId;
            this.showVideoEmbed = true;
          }
        //   else {
        //     console.log('Invalid YouTube video link');
        //   }
        },
        decodeBase64Url(encodedLink) {
            const decoded = decodeURIComponent(escape(atob(encodedLink))) ?? "";

            return decoded;
        },
    }
}
</script>