<template>
  <div class="exams">
    <h5 class="title">{{ $t("documents") }} ({{ documents?.length }})</h5>
    <ul class="exams_card">
      <li class="exam_card" v-for="(document, index) in documents" :key="index">
        <!-- :to="`${routeName == 'coursesDetailsPage' ? '/exam/'+exam.id : (routeName == 'lessonExam' || routeName == 'lesson') ? '/lesson/'+lessonID+'/exam/'+exam.id : ''}`" -->
        <div class="exam_check" v-if="document.available">
          <p class="name">
            <DocumentIcon :color="PrimaryColor" />
            {{ document.name }}
          </p>
          <div class="download">
            <button type="button" class="btn PrimaryButton px-4" @click="showDocument(document)">{{$t("show")}}</button>
          </div>
        </div>
        <div class="exam_check disabled" v-tooltip.top="'هذا الامتحان غير متاح لك'" v-else>
          <p class="name">
            <DocumentIcon :color="PrimaryColor" />
            {{ document.name }}
          </p>
          <div class="lock">
            <LockIcon :color="PrimaryColor" />
          </div>
        </div>
      </li>
    </ul>
  </div>

  <Dialog class="dialog_lesson" v-model:visible="visible" modal :style="{ width: '100%', height: '100vh' }">
    <iframe :src="document.url" width="100%" height="100%"></iframe>
  </Dialog>
</template>

<script>
import LockIcon from "@/assets/icons/lockIcon.vue";
import DocumentIcon from "@/assets/icons/documentIcon.vue";
import Dialog from 'primevue/dialog';

export default {
  name: "documents-component",
  data() {
    return {
      PrimaryColor: JSON.parse(localStorage.getItem("web_status"))?.primary_color,
      routeName: this.$route.name,
      lessonID: this.$route.params.id,
      visible: false,
      exam: {}
    }
  },
  props: {
    documents: Array
  },
  components:{
    LockIcon,
    DocumentIcon,
    Dialog
  },
  methods: {
    showDocument(document) {
      this.document = document;
      this.visible = true
    },
  }
}
</script>