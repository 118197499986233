<template>
    <div class="col-12 exam_full_screen" :class="{ 'col-lg-9 col-md-12': routeName == 'lessonExam' }" v-if="exam.available">
        <div class="exam_questions" id="fullScreenContent">
            <div class="container">
                <h4 class="title">{{ exam.name }}</h4>
                <div class="head_question">
                    <div class="title">
                        <p class="question">{{ $t("count_questions") }} : <span>{{ exam.questions_number }}</span></p>
                        <p class="category">{{ $t("category") }} : {{ exam.exam_type_name }}</p>
                    </div>
                    <div class="time" v-if="exam.is_duration_required === 1">
                        <i class="fa-regular fa-clock"></i>
                        {{ $t("remaining_time") }} : 
                        <span>{{ displayTime }}</span>
                    </div>
                    <div class="complete">
                        <button type="button" class="btn mx-3" @click="toggleFullScreen">{{ full_screen ? $t("end_full_screen") : $t("full_screen") }}</button>
                        <button type="button" class="btn" @click="EndExam">{{ $t("end") }}</button>
                    </div>
                </div>
                <div class="name_exam">{{ exam_data.description }}</div>

                <examQuestions :questions="questions" :exam="exam"  />
            </div>
        </div>
    </div>
    <div class="empty" v-else>
        <img src="@/assets/media/empty.png" alt="empty">
        <h4 class="text-center">{{ $t("exam_not_available") }}</h4>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import examQuestions from './examQuestions.vue'

export default {
    name: "exam-page",
    data() {
        return {
            exam: {},
            exam_data: {},
            questions: [],
            seconds: 0,
            minute: 1,
            visible: false,
            routeName: this.$route.name,
            full_screen: false
        }
    },
    props: {
        examDialog: Object
    },
    components: {
        examQuestions,
    },
    watch: {
        '$route.params.exam_id': {
            handler() {
                this.fetchExamQuestions();
                this.fetchExamDetails();
            },
            immediate: true
        }
    },
    methods: {
        fetchExamDetails() {
            const exam_id = {exam_id: this.$route.params.exam_id ? this.$route.params.exam_id : this.examDialog.id}
            axios
                .post(`/fetch_exam_details`, exam_id)
                .then(({ data }) => {
                    this.loading = true;
                    this.exam = data.data.exam_details;
                    if (this.exam.is_duration_required === 1) {
                        this.startTimer();
                    }
                })
                .catch((error) => {
                    return error;
                })
                .finally(() => (this.loading = false));
        },
        fetchExamQuestions() {
            const exam_id = {exam_id: this.$route.params.exam_id ? this.$route.params.exam_id : this.examDialog.id}
            axios
                .post(`/fetch_exam_questions`, exam_id)
                .then(({ data }) => {
                    this.loading = true;
                    this.exam_data = data.data;
                    this.questions = data.data.questions;

                    // Map through the questions array and add selectedAnswer and disabled properties
                    this.questions = this.questions.map(question => ({
                        ...question,
                        selectedAnswer: false,
                        selectedQuestion: false,
                        submitQuestion: false,
                        disabled: false,
                        visible: false
                    }));
                    // console.log(this.questions)

                    // console.log(this.questions)
                }).catch((error) => {
                    return error;
                    // this.$router.push({ path: "/teachers" });
                }).finally(() => (this.loading = false));
        },
        startTimer() {
            this.timer = setInterval(() => {
                if (this.exam_data.start_minute === 0 && this.seconds === 0) {
                    clearInterval(this.timer);
                    Swal.fire({
                        title: "",
                        text: this.$t("exam_time_ended"),
                        icon: "warning",
                    });
                    this.$router.push("/exams");
                } else {
                    this.decrementTime();
                }
            }, 1000);
        },
        decrementTime() {
            if (this.seconds > 0) {
                this.seconds--;
            } else if (this.exam_data.start_minute > 0) {
                this.exam_data.start_minute--;
                this.seconds = 59;
            } else {
                clearInterval(this.timer); // Stop the timer when both minutes and seconds reach zero
                Swal.fire({
                    title: "",
                    text: this.$t("exam_time_ended"),
                    icon: "warning",
                });
                this.$router.push("/exams");
            }
        },
        pad(value) {
            return value < 10 ? `0${value}` : value;
        },
        EndExam() {
            const isFullScreen = this.toggleFullScreen; // Assuming this holds the full-screen state
            Swal.fire({
                title: this.$t("do you need end_exam"),
                // text: this.$t("are_you_sure_delete"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: this.$t("back"),
                confirmButtonText: this.$t("end_exam"),
                customClass: {
                    container: isFullScreen ? 'swal-fullscreen' : '',
                },
                }).then((result) => {
                if (result.isConfirmed) {
                    if (document.fullscreenElement) {
                        const element = document.body;
                        element.classList.remove("full_screen");
                        // Exit fullscreen
                        if (document.exitFullscreen) {
                        document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                        }

                        // Reset element style
                        element.style.display = 'initial';

                        // toggle full screen False
                        this.full_screen = false
                    }

                    this.$router.push("/exams");
                }
            });
        },
        toggleFullScreen() {
            const element = document.body;
            if (!document.fullscreenElement) {
                // Add class full_screen
                element.classList.add("full_screen");

                // Enter fullscreen
                element.requestFullscreen().then(() => {
                    element.style.display = 'flex';
                });

                // toggle full screen true
                this.full_screen = true
            } else {
                // Remove class full_screen
                element.classList.remove("full_screen");

                // Exit fullscreen
                if (document.exitFullscreen) {
                document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
                }

                // Reset element style
                element.style.display = 'initial';

                // toggle full screen False
                this.full_screen = false
            }
        },
        preventReload(event) {
          event.preventDefault();
          event.returnValue = ''; // This is needed for older browsers
        }
    },
    computed: {
        displayTime() {
            return `${this.pad(this.exam_data.start_minute)}:${this.pad(this.seconds)}`;
        },
    },
    mounted() {
        this.fetchExamQuestions();
        this.fetchExamDetails();
        window.addEventListener('beforeunload', this.preventReload);
    },
    beforeUnmount() {
        clearInterval(this.timer);
        window.removeEventListener('beforeunload', this.preventReload);
    },
}
</script>
